// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-can-gallery-js": () => import("./../../../src/pages/can-gallery.js" /* webpackChunkName: "component---src-pages-can-gallery-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dog-gallery-js": () => import("./../../../src/pages/dog-gallery.js" /* webpackChunkName: "component---src-pages-dog-gallery-js" */),
  "component---src-pages-galleries-js": () => import("./../../../src/pages/galleries.js" /* webpackChunkName: "component---src-pages-galleries-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kl-gallery-js": () => import("./../../../src/pages/kl-gallery.js" /* webpackChunkName: "component---src-pages-kl-gallery-js" */),
  "component---src-pages-mmh-gallery-js": () => import("./../../../src/pages/mmh-gallery.js" /* webpackChunkName: "component---src-pages-mmh-gallery-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-templates-painting-js": () => import("./../../../src/templates/painting.js" /* webpackChunkName: "component---src-templates-painting-js" */)
}

